.section-projets {
  .top-text {
    text-align: center;
    color: $color-primary;
    font-size: $font-size-24;
    padding: 50px 10px;
    max-width: 600px;
    margin: auto;
  }
    p {
        font-size: 16px;
    }
    &--item {
        cursor: pointer;
        margin-bottom: 8vh;
        margin-left: 5vw;
        margin-right: 5vw;
        padding-right: 15vw;
        position: relative;
        border: 2px solid #a08067;
        /* background-image: linear-gradient(to right, rgba(123, 84, 53, 0.75), #66512c); */
        .overlay {
            //mix-blend-mode: multiply;
            //background-color: white;
            background-image: url(../images/icon_magnifier.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.3s ease-out;
        }
        &:hover {
            .section-projets--image {
                img {
                    transform: scale(1.1, 1.1);
                    transition: 0.2s all ease-in-out;
                }
            }
            .overlay {
                opacity: 1;
                background-position: center, center;
                // IE 10 and 11
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    opacity: 0.5;
                }
                // For IE and Edge
                @supports (-ms-ime-align:auto) {
                    opacity: 0.5;
                }
            }
        }
    }
    &--image {
        max-height: 175px;
        overflow: hidden;
        margin-right: 2vw;
        min-width: 30%;
        max-width: 300px;
        img {
            transition: 0.2s all ease-in-out;
        }
    }
    &--text {
        padding: 10px 0;
    }
    &--title {
        h3 {
            font-size: 1.5vw;
            font-weight: 400;
            margin-bottom: 10px;
            color: $color-primary;
        }

        h3.type-project {
            font-size: 24px;
            color: rgba(160, 128, 103, 0.78);
            text-transform: none;
            //padding: 10px 0;
        }
    }
    &--description {
        p, li {
            font-weight: 200;
            color: $color-white;
        }
    }
}

div.top-title {
    padding: 45px;
    display: flex;
    justify-content: center;
    h3 {
        text-align: center;
        background: $color-primary;
        padding: 20px;
        font-weight: 200;
        font-size: 44px;
        color: #FFF;
        margin: 20px 0;
        width: 94%;
        font-family: $font-family-1;
    }
}
/* purgecss start ignore */
.amazingslider-wrapper-1 {
    .amazingslider-title-1 {
        color: $color-white;
        font-weight: 500;
        font-size: 16px
    }
    .amazingslider-description-1 p,
    .amazingslider-description-1 li {
        color: $color-white;
        font-size: 14px;
    }
    .amazingslider-bullet-1 {
        box-sizing: content-box;
        color: $color-white;
    }
}

.fiche.projet {
    h3.page-subtitle, div.page-description {
        margin: 25px auto;
        max-width: 900px;
    }
    h3.page-subtitle {
        text-align: center;
        font-size: 30px;
        color: $color-primary;
        font-weight: 300;
    }
    div.page-description p,
    div.page-description li {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
    }
}

////* Gallérie Réalisations */
.gallerie-realisations {
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Create four equal columns that floats next to eachother */
    .column {
        float: left;
        width: 25%;
    }

    /* The Modal (background) */
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: white;
    }


    /* Modal Content */
    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        width: 90%;
        max-width: 1200px;
    }

    /* The Close Button */
    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
    }

    /* Hide the slides by default */
    .mySlides {
        display: none;
    }

    /* Next & previous buttons */
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }

    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    /* Caption text */
    .caption-container {
        text-align: center;
        background-color: black;
        padding: 2px 16px;
        color: white;
    }

    img.demo {
        opacity: 0.6;
    }

    .active,
    .demo:hover {
        opacity: 1;
    }

    img.hover-shadow {
        transition: 0.3s;
    }

    .hover-shadow:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    /* purgecss end ignore */
}


section.realisations04 {
    width: 100%;
    margin: 0 auto;
    background: $color-black;
    div#masonry {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100vw;
        //max-height: 800px;
        font-size: 0;
    }
    div#masonry img {
        width: 33.3%;
        transition: .8s opacity;
    }

    div#masonry:hover img { opacity: 0.3; }
    div#masonry:hover img:hover { opacity: 1; }

      /* fallback for earlier versions of Firefox */

    @supports not (flex-wrap: wrap) {
        div#masonry {
            display: block;
        }
        div#masonry img {
            display: inline-block;
            vertical-align: top;
        }
    }
}
/* purgecss start ignore */
.mfp-iframe-holder {
    .mfp-content {
        max-width: 1375px;
        min-height: 95%;
        background: $color-white!important;
    }
}
/*******************************************************************************
* MOBILE OVERRIDES
*/

@media (max-width: $size-sm-max) {
    .section-projets {
        &--item {
            margin-left: 5vw;
            padding-right: 5vw;
        }
        &--image {
            max-height: 220px;
            overflow: hidden;
            margin-right: 3vw;
            img {
                transition: 0.2s all ease-in-out;
            }
        }
        &--title {
            h3 {
                font-size: 16px;
                font-weight: 00;
                margin-bottom: 10px;
            }
        }
        &--description {
            p, li {
                font-size: 14px;
                line-height: 1.2;
            }
        }
    }
    .amazingslider-wrapper-1 {
        .amazingslider-title-1 {
            font-weight: 500;
            font-size: 14px
        }
        .amazingslider-description-1 p,
        .amazingslider-description-1 li {
            font-size: 12px;
        }
    }
    div.top-title {
        padding: 45px 0;
        h3 {
            font-size: 26px;
          }
      }
}
@media (max-width: $size-xs-max) {
    .section-projets {
        &--item {
            margin-left: auto;
            margin-right: auto;
            padding-right: 0;
            flex-direction: column;
            max-width: 600px;
        }
        &--image {
            max-height: 80vw;
            margin-right: 0;
        }
        &--text {
            padding: 5vw;
        }
    }
}
