section.section-mask {

    div.container-fluid {        
        @media screen and (max-width: 1185px) {
            //min-height: 650px;
            transition: .5s;
        }
    }

    div.left-side {
        background-image: url(../images/parallax_bg_v1_left_opt_01.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @media screen and (max-width: 1185px) {
            //display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
        }

        & > .content-armor {
            position: relative;
            overflow: hidden;

            & > img.size-image {
                opacity: 0;
                visibility: hidden;
            }

            & > img.piece {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                //transition: all $transition-speed-normal;
                &.top-slow {
                    transition: all $transition-speed-normal ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateY(-200%);
                        transition: all $transition-speed-very-fast ease-in;
                    }
                }

                &.top-fast {
                    transition: all $transition-speed-very-fast ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateY(-200%);
                        transition: all $transition-speed-normal ease-in;
                    }
                }

                &.left-fast {
                    transition: all $transition-speed-very-fast ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateX(-200%);
                        transition: all $transition-speed-normal ease-in;
                    }
                }

                &.bottom-fast {
                    transition: all $transition-speed-very-fast ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateY(200%);
                        transition: all $transition-speed-normal ease-in;
                    }
                }

                &.bottom-middle {
                    transition: all $transition-speed-fast ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateY(200%);
                        transition: all $transition-speed-fast ease-in;
                    }
                }

                &.bottom-slow {
                    transition: all $transition-speed-normal ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateY(200%);
                        transition: all $transition-speed-very-fast ease-in;
                    }
                }

                &.right-fast {
                    transition: all $transition-speed-very-fast ease;
                    transform: translateY(0);

                    &.inactive {
                        transform: translateX(200%);
                        transition: all $transition-speed-normal ease-in;
                    }
                }

                &.center {
                    transition: all $transition-speed-very-slow ease;
                    opacity: 1;

                    &.inactive {
                        opacity: 0;
                        transition: all $transition-speed-very-fast ease-in;
                    }
                }
            }
        }

    }

    .right-side {
        @extend .flex;
        @extend .flex-column;
        @extend .justify-center;

        .title-box, .text-box, .logo-box {
            @extend .flex;
            @extend .items-center;
            @extend .justify-center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            & > div {
                width: 80%;
                @media screen and (max-width: 1185px) {
                    width: 90%;
                    transition: .5s;
                }
            }
        }

        p {
            @extend .font-main;
            text-shadow: 0 0 10px $color-black;

            span {
                @extend .font-2-bold;
                font-weight: 700;
                color: $color-primary;
            }

            &.small {
                font-size: $font-size-18;
                line-height: 1.5;
            }

            @media screen and (max-width: 1585px) {
                font-size: 18px;
                transition: .5s;
                @media screen and (max-width: 1185px) {
                    font-size: 16px;
                    line-height: 22px;
                    transition: .5s;
                    @media screen and (max-width: 991px) {
                        font-size: 15px;
                        line-height: 18px;
                        transition: .5s;
                    }
                }
            }
        }

        .title-box {
            width: 100%;
            min-height: 20%;
            background-attachment: fixed;
            background-image: url('../images/parallax_bg_v1_right_pt_01.jpg');

            h3 {
                color: $color-primary;
                font-size: $font-size-40;
                @extend .font-title;
                @media screen and (max-width: 1255px) {
                    font-size: 24px;
                    transition: .5s;
                }
            }

            &.small {
                h3 {
                    font-size: $font-size-30;
                }
            }
        }


        .text-box {
            min-height: 60%;
            background-image: url('../images/parallax_bg_v2_right_pt_02.jpg');
            @extend .flex-column;
            @media screen and (max-width: 1185px) {
                min-height: 58%;
                transition: .5s;
            }

            &.small {
                min-height: 50%;
            }
        }

        .logo-box {
            min-height: 20%;
            background-image: url('../images/parallax_bg_v1_right_pt_03.jpg');
            transition: .5s;
            @media screen and (max-width: 1185px) {
                min-height: 22%;
                transition: .5s;
            }
            h3 {
                color: $color-primary;
                font-size: $font-size-40;
                @extend .font-title;
            }

            & > div {
                @extend .flex;
                @extend .items-center;
                @extend .justify-center;
            }

            &.small {
                min-height: 30%;
            }
        }

        p.bottom-text {
            color: $color-primary;
            font-weight: 700;
            transition: .5s;
            @media screen and (max-width: 1585px) {
                font-size: 18px;
                line-height: 27px;
                transition: .5s;
                @media screen and (max-width: 1305px) {
                    font-size: 16px;
                    line-height: 25px;
                    transition: .5s;
                    @media screen and (max-width: 1185px) {
                        font-size: 15px;
                        line-height: 20px;
                        transition: .5s;
                        @media screen and (max-width: 991px) {
                            font-size: 14px;
                            line-height: 16px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .left-side {
            display: none;
        }
        .right-side {
            & > div {
                height: 100% !important;
                padding: 30px 0;
            }
        }
    }

}
