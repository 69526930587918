.section-form {
    background: $color-black;
    padding: 50px 0;

    .row {
        margin: auto;
    }

    .title-top {
        color: $color-primary;
        text-align: center;
        padding: 50px 0;
    }

    //.form-group {padding: 0;}
    .form-control {
        border-radius: 0;
        border: 1px solid $color-primary;
        margin: 20px 0;
        color: $color-black;
        &::placeholder {
            //color: $color-black;
        }
    }

    .g-recaptcha div {
        margin: auto;
    }

    @media screen and (max-width: $size-md-max) {
        .g-recaptcha {
            margin: auto;
            transform: scale(0.9);
            -webkit-transform: scale(0.9);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        .g-recaptcha {
            margin: auto;
            transform: scale(0.7);
            -webkit-transform: scale(0.7);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .g-recaptcha {
            margin: auto;
            transform: scale(1);
            -webkit-transform: scale(1);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
    @media screen and (max-width: 400px) {
        .g-recaptcha {
            margin: auto;
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
}
