section.coordonnees-01 {

    div.left-side {
      background-image: url(../images/parallax_bg_v1_left_opt_02.jpg);
      padding-top: 10%;
    }
    div.right-side {
      h4 {
        font-size: $font-size-30;
        font-weight: 500;
        line-height: 1.5;
        &.mail {
          padding: 15px 0;
          color: $color-black;
          font-size: $font-size-18;
          text-transform: uppercase;
          a {
            color: $color-white;
            font-weight: 500;
            text-transform: lowercase;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
      h3.phone {
        a {
          color: $color-primary;
          font-size: $font-size-40;
          @extend .font-2-bold;
          &:hover {color: $color-black;}
        }
      }
      .logo-box {
        h3 {
          font-size: $font-size-30;
        }
      }
      @media screen and (max-width: $size-md-max){
        .title-box {
          img {
            max-width: 250px;
          }
        }
        h4 {
          font-size: $font-size-24;
          line-height: 1.2;
          &.mail {
            padding: 5px 0;
            font-size: $font-size-18;
          }
        }
      }
      @media screen and (max-width: $size-xs-max){
        text-align: center;
        .title-box {
          img {
            margin: auto;
          }
        }
        h4 {
          font-size: $font-size-30;
          &.mail {
            font-size: $font-size-24;
          }
        }
      }
    }
}
