
/** to add hover effect on icon **/
.filter-hover {filter: invert(100%) sepia(4%) saturate(16%) hue-rotate(182deg) brightness(99%) contrast(105%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(65%) sepia(69%) saturate(1489%) hue-rotate(355deg) brightness(99%) contrast(94%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-color: $bg-color-nav-highlight;
            mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}
