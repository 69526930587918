.slideshow-wrap {
    position: relative;
    //@extend .bg-color-black;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .caption {
      position: absolute;
      width: 100%;
      top: 20%;
      z-index: 99;
      img {
        margin: auto;
        max-width: 20%;
      }
    }
    .subtitle {
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        text-shadow: 2px 2px 10px #000,
                    -2px -2px 10px #000;
    }
    .title {
        @extend .font-2-normal;
        color: $color-primary;
        font-size: 4vw;
        text-align: center;
        text-shadow: 2px 2px 10px #000,
                    -2px -2px 10px #000;
        span {
          font-weight: 800;
          font-size: 4.5vw;
        }
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: 991px) {
      .caption {
        top: 80px;
        img {
          max-width: 250px;
        }
      }
    }
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}
