// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 1;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';

@import 'modules/module-header';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
//@import 'modules/module-header';

/*****************************************************/
/* INDEX.PHP
/*****************************************************/
@import 'sections/section-mask';

@import 'sections/section-doors';

@import 'sections/section-carousel';

@import 'sections/section-03';

@import 'sections/section-projets';

@import 'sections/section-coordonnees-01';

@import 'sections/section-form';

/*******************************************************************************
* COMMON TO ALL PAGES
*/
section.section {
@extend .flex;
@extend .justify-center;
@extend .items-center;
div.container-fluid {
  @extend .flex;
  @extend .justify-center;
  @extend .items-stretch;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
    div.left-side, div.right-side {
      width: 50%;
    }
    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      div.left-side, div.right-side {
        width: 100%;
      }
    }
  }
}








/*******************************************************************************
* NOUVELLE PAGE - EMPLOI
*******************************************************************************/

#section-emplois {
    background: #000;
    padding: 75px 5% 50px 5%;
    .section-intro {
        padding-bottom: 35px;
        h3 {
            text-align: center;
            font-weight: 900;
            font-size: $font-size-40;
            color: #e19510;
            padding-bottom: 25px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .section-job {
        h4 {
            font-weight: 900;
            font-size: $font-size-28;
            text-align: center;
            text-transform: uppercase;
            color: #e19510;
            padding-bottom: 25px;
        }
        .item-poste.emploi {
            background: #fff;
            padding: 50px 5%;
            margin-bottom: 50px;
            h4 {
                text-align: initial;
                padding-bottom: 10px;
            }
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: #585858;
            }
            .content-full {
                padding-top: 50px;
                p { 
                    font-weight: 500;
                    font-size: $font-size-20;
                    line-height: 1.5;
                    margin: 0;
                    color: #585858;
                }
                ul {
                    padding-top: 25px;
                    padding-left: 30px;
                    li {
                        font-weight: 500;
                        font-size: $font-size-20;
                        text-transform: initial;
                        line-height: 1.3;
                        padding-bottom: 5px;
                        color: #585858;
                    }
                }
            }
            .btn {
                margin-top: 35px;
                width: 175px;
                padding: 10px;
            }
        }
    }
}

#section-form {
    padding: 75px 0 100px 0;
    background: #d6d6d6;
    h3 {
        font-weight: 900;
        font-size: $font-size-26;
        text-transform: uppercase;
        color: #e19510;
        padding-bottom: 25px;
    }
    @media screen and (max-width: $size-sm-max) {
        .col-xs-12.col-sm-4.form-group {
            width: 28%;
        }
        div#visible-door {
            margin-left: 120px;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            .col-xs-12.col-sm-4.form-group {
                width: 100%;
            }
            div#visible-door {
                margin-left: 0;
                margin-top: 10px;
            }
            .g-recaptcha div {
                margin: auto;
            }
        }
    }
}

/*******************************************************************************
* NOUVELLE PAGE - RÉALISATION
*******************************************************************************/

section.section04.realisations04 {
    .section-projets {
        h3.top-text {
            font-weight: 900;
            font-size: $font-size-32;
            max-width: 800px;
        }
    }
}

#section-realisations {
    padding: 75px 5%;
    background: #000;
    .realisations.zoom-gallery.grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        //align-items: start;
        //justify-items: center;
        .item {
            transition: 0.3s;
            .img-responsive {
                width: 100%;
            }
            &:hover {
                transform: scale(1.05); 
                transition: 0.3s;
            }
        }
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);
            }
        }

        #blocIntro_promotions {
            h3 {
                font-weight: bold;
                color: #fff;
            }
        }
    }
}










/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
    

}
