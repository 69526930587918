// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
       url('../fonts/raleway/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/raleway/raleway-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium'), local('Raleway-Medium'),
       url('../fonts/raleway/raleway-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway/raleway-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway/raleway-v12-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/raleway/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
       url('../fonts/raleway/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway/raleway-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/raleway/raleway-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
       url('../fonts/raleway/raleway-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway/raleway-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway/raleway-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway/raleway-v12-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* nova-square-regular - latin */
@font-face {
  font-family: 'Nova Square';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nova-square/nova-square-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nova Square'), local('NovaSquare'),
       url('../fonts/nova-square/nova-square-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nova-square/nova-square-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nova-square/nova-square-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nova-square/nova-square-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nova-square/nova-square-v11-latin-regular.svg#NovaSquare') format('svg'); /* Legacy iOS */
}

//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Raleway", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: "Nova Square", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1-normal { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1-normal; font-weight: 300; }
.font-1-medium { @extend .font-1-normal; font-weight: 500; }
.font-1-bold { @extend .font-1-normal; font-weight: 700; }
// Libre Baskerville
.font-2-normal { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2-normal; font-weight: 700; }



// MAIN FONTS
.font-main {
    @extend .font-1-normal;
    @extend .text-color;
    font-size: $font-size-20;
    @media screen and (max-width: $size-md-max) {
      line-height: 1.5;
    }
}


// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-1-medium;
    font-size: $font-size-16;
    line-height: 1;
    text-transform: uppercase;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-2-bold;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-medium;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-2-bold;
    font-size: $font-size-22;
}
.font-nav-lang {
  @extend .font-1-normal;
  font-size: $font-size-18;
}
// si le logo est du texte
.font-nav-logo {
    @extend .font-2-normal;
    font-size: $font-size-28;
    //@media screen and (max-width: $size-sm-max){
    //    font-size: $font-size-20;
    //}
    //@media screen and (max-width: $size-xs-max){
    //    font-size: $font-size-14;
    //}
}

.font-title {
  @extend .font-2-normal;
  font-size: $font-size-50;
  text-shadow: 0 0 10px $color-black;
}

// FOOTER FONTS

.font-footer {
    @extend .font-1-normal;
    font-size: $font-size-14;
}
