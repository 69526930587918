.section-03 {
  &.moulures {
    .left-side {
      padding-bottom: 0!important;
      img{margin-top: 30px;}
      h4 {
        padding: 30px 15%;
      }
    }
  }
  .left-side {
    background-image: url('../images/parallax_bg_v1_left_opt_02.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 30px 0;
    @extend .flex;
    @extend .items-center;
    @extend .justify-start;

    & > div.content {
      width: 80%;
      text-align: center;
      h3 {
        margin: 20px 0;
        @extend .font-title;
        color: $color-primary;
        font-size: $font-size-30;
      }
      .liste-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-top: 2px solid $color-primary;
        border-bottom: 2px solid $color-primary;
        .item {
          display: flex;margin: 15px auto;
          align-items: center;
          width: 260px;
          h4 {
            padding-left: 30px;
          }
        }
      }
      p {
        margin: 20px 0;
        color: $color-primary;
      }
    }
  }
  .right-side {
    @extend .flex;
    @extend .flex-column;
    @extend .justify-center;

    .title-box, .text-box {
      @extend .flex;
      @extend .items-center;
      @extend .justify-center;
      padding: 50px 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      & > div {
        width: 80%;
      }
    }

    .title-box {
      width: 100%;
      height: 30%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../images/parallax_bg_v1_right_pt_03.jpg');
      h3 {
        color: $color-primary;
        @extend .font-title;
        font-size: $font-size-40;
      }
    }


    .text-box {
      height: 70%;
      background-image: url('../images/parallax_bg_v2_right_pt_02.jpg');
      @extend .flex-column;
      p {
        @extend .font-main;
        text-shadow: 0 0 10px $color-black;
        span {
          @extend .font-2-bold;
          font-weight: 700;
          color: $color-primary;
        }
      }
    }


  }
  @media screen and (max-width: $size-xs-max){
    .left-side {
      & > div.content {width: 100%;}
    }
  }

}
