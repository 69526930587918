.btn {
    background-color: $color-primary;
    background: $color-primary;
    color: $color-white;
    border: 3px solid $color-primary;
    border-radius: 0px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    opacity: 1!important;
    border
    span.btn-em {
        font-size: 20px;
        text-transform: uppercase;
        font-weigth: 600;
    }
    &:hover {
        color: $color-black;
        background: #b57402!important;
        border: 3px solid #b57402!important;
    }
}
