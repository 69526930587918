.bursts {
    width: 100%;
    background: $color-black;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            z-index: $z-index-burst;
            height: 230px;
            position: relative;

            transition: transform .33s; /* Animate the transform properties */
            transform-style: preserve-3d;

            /* The two faces of the cube */
            .flippety, .flop {
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 7px;

	            box-shadow: 0 0 20px $color-black inset;
              height: 230px;
              img.whith {
                opacity: 0;
                visibility: hidden;
              }
            }
            /* Position the faces */
            .flippety {
            	transform: translateZ(115px);
            }

            .flop {
            	transform: rotateX(-90deg) translateZ(-115px);
            }

            .text-container {
                padding: 1.25rem;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                .border {
                    border: 2px solid $color-white;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                    img {
                      border-top: 2px solid $color-white;
                      border-bottom: 2px solid $color-white;
                      padding: 5px 60px;
                    }
                }
            }

            p {
              text-transform: uppercase;
              text-align: center;
              font-weight: 700;
              font-size: $font-size-24;
            }
            &:hover {
              transform: rotateX(90deg); /* Text bleed at 90º */
            }
        }

    }
}
@media screen and (max-width: $size-sm-min) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}
