.section-doors {
    position: relative;

    .left-door, .right-door {
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        bottom: 0;
        z-index:1000;

        @media screen and (max-width: $size-xs-max) {
            display: none;
        }
    }

    .left-door {
        left: 0;
        transition: all $transition-speed-normal ease;
        transform: translate3d(-100%, 0, 0);

        &.inactive {
            transform: translate3d(0, 0, 0);
            transition: all $transition-speed-normal ease;
        }

    }

    .right-door {
        left: 50%;
        transition: all $transition-speed-normal ease;
        transform: translate3d(100%, 0, 0);

        &.inactive {
            transform: translate3d(0, 0, 0);
            transition: all $transition-speed-normal ease;
        }

    }

    .right-door {
        left: 50%;
    }

    .left-side {
        background-image: url('../images/section_03_bg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 30px 0;
        @extend .flex;
        @extend .items-center;
        @extend .justify-center;

        & > div.content {
            img {
                margin: auto;
            }

            text-align: center;

            h3 {
                @extend .font-title;
                color: $color-primary;
            }

            p {
                border-top: 2px solid $color-primary;
                border-bottom: 2px solid $color-primary;
                margin: 20px 0;
            }
        }
    }

    .right-side {
        @extend .flex;
        @extend .flex-column;
        @extend .justify-center;

        .title-box, .text-box {
            @extend .flex;
            @extend .items-center;
            @extend .justify-center;
            padding: 50px 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            & > div {
                width: 80%;
            }
        }

        .title-box {
            height: 30%;
            background-attachment: fixed;
            background-image: url('../images/parallax_bg_v1_right_pt_01.jpg');

            h3 {
                color: $color-primary;
                @extend .font-title;
                font-size: $font-size-40;
            }
        }


        .text-box {
            height: 70%;
            background-image: url('../images/parallax_bg_v2_right_pt_02.jpg');
            @extend .flex-column;

            p {
                @extend .font-main;
                text-shadow: 0 0 10px $color-black;

                span {
                    @extend .font-2-bold;
                    font-weight: 700;
                    color: $color-primary;
                }
            }
        }
    }

}
