.module-header {
    position: relative;

    .caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        div.content-box {
          background: rgba(0,0,0,0.5);
          padding: 15px;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-size: $font-size-60;
            text-transform: uppercase;
            text-shadow: 2px 2px 10px $color-black,
                        -2px -2px 10px $color-black;
            color: $color-white;
            @extend .font-2-normal;
        }
    }
    .mouse {
      max-width: 2.5rem;
      width: 100%;
      height: auto;
    }
    .scroll {
      animation-name: scroll;
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
      animation-iteration-count: infinite;
      transform-origin: 50% 20.5px;
      will-change: transform, opacity;
      opacity: 1;
    }
    .scroll-link {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  @keyframes scroll {

      0%, 20% {
        transform: translateY(0) scaleY(1);
      }
      100% {
        transform: translateY(36px) scaleY(2);
        opacity: 0;
      }
    }
}
.interieur, .moulures, .exterieur {
    .module-header {
        .caption {
            h3 {
                color: $color-black;
                text-shadow: 2px 2px 10px #fff, -2px -2px 10px #fff;
            }
        }
    }
}
.realisations {
    .top-text {
        text-transform: uppercase;
    }

}
